import React, { useState } from "react"
import { SERVICE_PRICE_TYPES, Service, ServiceOptionType } from "../data/types"
import ServiceCard from "./ServiceCard.component"

type ServiceListProps = {
  service: ServiceOptionType
}

const ServiceList = ({ service }: ServiceListProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(true)
  const onPressToggleAction = () => setIsOpened(!isOpened)

  const renderMoreOptions = (service: Service) => {
    const renderOptions = (item: Service, index: number) => {
      return <ServiceCard key={index} service={item} />
    }

    if (service.options && service.options.length > 0) {
      return <>{service.options.map(renderOptions)}</>
    }
    return null
  }

  const renderServices = (item: Service, index: number) => {
    if (item.priceType === SERVICE_PRICE_TYPES.VARIES) {
      return (
        <div key={index} className="service-options">
          <section className="service-info header">
            <label>{item.name}</label>
            <p>Select an option</p>
          </section>
          {renderMoreOptions(item)}
        </div>
      )
    }
    return <ServiceCard key={index} service={item} />
  }

  const renderAllServices = isOpened && (
    <div>{service.services?.map(renderServices)}</div>
  )

  if (service.services.length > 0) {
    const iconClassName = `fa-solid fa-chevron-${isOpened ? "down" : "up"}`
    return (
      <div className="service-container">
        <section className="service-category" onClick={onPressToggleAction}>
          <label>{service.category}</label>
          <i className={iconClassName}></i>
        </section>
        {renderAllServices}
      </div>
    )
  } else {
    return null
  }
}

export default ServiceList
