import React from "react"
import { SERVICE_PRICE_TYPES, Service } from "../data/types"

type OwnProps = {
  service: Service
}

const ServiceCard = ({ service }: OwnProps) => {
  const price =
    service?.priceType === SERVICE_PRICE_TYPES.FIXED
      ? `Fixed Price Rs ${service.price}`
      : service?.priceType === SERVICE_PRICE_TYPES.ONWARDS
      ? `Starting At Rs ${service.price}`
      : "Select an option"

  return (
    <div className="service-card">
      <section className="service-info">
        <label>{service.name}</label>
        <p>{price}</p>
      </section>
    </div>
  )
}

export default ServiceCard
