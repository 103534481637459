import React from "react"

type OwnProps = {
  isOpen: boolean
  title?: string
  toggle: () => void
  footer?: JSX.Element | JSX.Element[]
  children?: JSX.Element | JSX.Element[]
}

const Modal = ({ isOpen, title, toggle, footer, children }: OwnProps) => {
  if (!isOpen) return null
  return (
    <div className="custom-modal display-block">
      <div className="modal-main">
        <div className="modal-header">
          <h5>{title}</h5>
          <button onClick={toggle}>
            <i className="fa-solid fa-xmark"></i>
          </button>
        </div>
        <div className="modal-body">{children}</div>
        {footer && <div className="modal-footer">{footer}</div>}
      </div>
    </div>
  )
}

export default Modal
