import React, { useState } from "react"

import { SERVICE_CATEGORIES, SalonCard, ServiceOptionType } from "../data/types"
import { webAppUrl } from "../data/config"
import { Helmet } from "react-helmet"
import { getAddressString, getServicesByCategories } from "../util"
import moment from "moment"
import Navbar from "../components/Navbar.component"
import Avatar, { Avatar_Type } from "../components/Avatar.component"
import AboutUs from "../components/AboutUs.component"
import Footer from "../components/Footer.component"
import ServiceList from "../components/ServiceList.component"
import Share from "../components/Modals/Share.modal"

type OwnProps = {
  pageContext: SalonCard
}

type Offer = {
  "@type": "Offer"
  itemOffered: {
    "@type": "Service"
    name: string
  }
}

type ServiceSchema = {
  "@type": "OfferCatalog"
  name: string
  itemListElement: Offer[]
}

type AllServices = {
  [SERVICE_CATEGORIES.FACE]: ServiceSchema
  [SERVICE_CATEGORIES.HAIR]: ServiceSchema
  [SERVICE_CATEGORIES.MANICURE_PEDICURE]: ServiceSchema
  [SERVICE_CATEGORIES.BODY]: ServiceSchema
  [SERVICE_CATEGORIES.DRESSING]: ServiceSchema
  [SERVICE_CATEGORIES.OTHER]: ServiceSchema
}

const days = ["Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]

const Vendors = ({ pageContext }: OwnProps) => {
  const [showShare, setshowShare] = useState<boolean>(false)
  const allServices = getServicesByCategories(pageContext.services)
  const fullAddress = getAddressString(
    pageContext.contact.address,
    false,
    false
  )
  const address = getAddressString(pageContext.contact.address, false, true)
  const description =
    !pageContext.description || pageContext.description.length === 0
      ? `${pageContext.salon} | ${address} | GLOWAPP`
      : pageContext.description

  const title = `${pageContext.salon} | ${pageContext.contact.address.district} | GLOWAPP`
  const image =
    pageContext?.profilePicture || "https://www.glowapp.info/img/appiconv2.webp"
  const url = `${webAppUrl}/vendor/${pageContext.vendorId}?name=${pageContext.salon}`
  const getOpeningHours = () => {
    const unavailableDays = pageContext.availability?.unavailableDays.map(
      _day => _day[2]
    )
    const availableDays = days
      .filter(_day => !unavailableDays?.includes(_day))
      .join(",")

    return `${availableDays} ${moment(
      pageContext.availability.opens,
      "h:mm"
    ).format("HH:mm")}-${moment(pageContext.availability.closes, "h:mm").format(
      "HH:mm"
    )}`
  }

  const getItemListElement = () => {
    const allServices: AllServices = {
      [SERVICE_CATEGORIES.FACE]: {
        "@type": "OfferCatalog",
        name: "Face / Facial",
        itemListElement: [],
      },
      [SERVICE_CATEGORIES.HAIR]: {
        "@type": "OfferCatalog",
        name: "Hair / Hair treatments",
        itemListElement: [],
      },
      [SERVICE_CATEGORIES.MANICURE_PEDICURE]: {
        "@type": "OfferCatalog",
        name: "Manicure / Pedicure",
        itemListElement: [],
      },
      [SERVICE_CATEGORIES.BODY]: {
        "@type": "OfferCatalog",
        name: "Body / Waxing",
        itemListElement: [],
      },
      [SERVICE_CATEGORIES.DRESSING]: {
        "@type": "OfferCatalog",
        name: "Bridal / Dressing",
        itemListElement: [],
      },
      [SERVICE_CATEGORIES.OTHER]: {
        "@type": "OfferCatalog",
        name: "Other",
        itemListElement: [],
      },
    }

    pageContext.services.forEach(_service => {
      allServices[_service.category].itemListElement.push({
        "@type": "Offer",
        itemOffered: { "@type": "Service", name: _service.name },
      })
    })

    const schema = Object.keys(allServices)
      .map((_key: any) => {
        const itemKey = _key as SERVICE_CATEGORIES
        if (allServices[itemKey].itemListElement.length > 0) {
          return allServices[itemKey]
        }
        return null
      })
      .filter(_item => _item !== null)

    return schema
  }

  const toggleShare = () => setshowShare(!showShare)

  const renderOpenOrClosed = () => {
    const now = moment()
    const open = moment(pageContext.availability.opens, "hh:mm A")
    const close = moment(pageContext.availability.closes, "hh:mm A")

    if (now.isBetween(open, close)) {
      return (
        <p className="availability">
          <span className="open">Open</span> | Closes @{" "}
          {pageContext.availability.closes}
        </p>
      )
    } else {
      return (
        <p className="availability">
          <span className="closed">Closed</span> | Opens @{" "}
          {pageContext.availability.opens}
        </p>
      )
    }
  }

  const renderServiceList = (item: ServiceOptionType, index: number) => (
    <ServiceList key={index} service={item} />
  )

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={url} />
        <meta property="og:title" content={title} />
        <meta property="og:image" content={image} />
        <meta name="og:description" content={description} />
        <meta name="description" content={description} />
        <meta property="og:url" content={url} />
        <meta name="keywords" content={title} />

        <meta http-equiv="content-type" content="text/html; charset=utf-8" />
        <meta http-equiv="content-language" content="en" />
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#994653" />
        <meta property="al:ios:app_name" content="GLOWAPP LK" />
        <meta property="al:ios:app_store_id" content="1639030942" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="1024" />
        <meta property="og:type" content="article" />
        <link rel="apple-touch-icon" href="/logo192.png" />

        <link rel="manifest" href="/manifest.json" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta
          name="apple-mobile-web-app-status-bar-style"
          content="black-translucent"
        />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Alata&family=Roboto:wght@100;300;400;500&display=swap"
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css"
          integrity="sha512-KfkfwYDsLkIlwQp6LFnl8zNdLGxu9YAA1QvwINks4PhcElQSvqcyVLLD9aMhXd13uQjoXtEKNosOWaZqXgel0g=="
          crossOrigin="anonymous"
          referrerPolicy="no-referrer"
        />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <script>
          {`!function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1354891995084382');
            fbq('track', 'PageView');`}
        </script>
        <noscript>
          {`<img
            height="1"
            width="1"
            style={{ display: "none" }}
            src="https://www.facebook.com/tr?id=1354891995084382&ev=PageView&noscript=1"
          />`}
        </noscript>
        <script type="application/ld+json">
          {`{
              "@context": "https://schema.org",
              "@type": "HealthAndBeautyBusiness",
              "name": "${title}",
              "address": "${getAddressString(pageContext.contact.address)}",
              "description": "${description}",
              "currenciesAccepted": "LKR",
              "openingHours": "${getOpeningHours()}",
              "image": "${image}",
              "geo": {
                "@type": "GeoCoordinates",
                "latitude": ${pageContext.contact.location.latitude},
                "longitude": ${pageContext.contact.location.longitude}
              },
              "telephone": "${pageContext.contact.phone}",
              "hasOfferCatalog": {
                "@type": "OfferCatalog",
                "itemListElement": ${JSON.stringify(getItemListElement())}
              }
            }`}
        </script>
      </Helmet>
      <div className="body">
        <Navbar bg="primary" />
        <div className="hero-container vendor-hero">
          <div className="container-lg">
            <div className="salon-hero">
              <div className="salon-info">
                <h1>{pageContext.salon}</h1>
                {renderOpenOrClosed()}
                <label>{fullAddress}</label>
                <p>{description}</p>
              </div>
              <div className="salon-contacts">
                <Avatar
                  src={pageContext?.profilePicture}
                  name={pageContext.salon}
                  type={Avatar_Type.LARGE}
                />
              </div>
            </div>

            <div className="booknow-container">
              <div className="booknow-btns-container">
                <a href={url} type="button" className="booknow-btn">
                  Book now
                </a>

                <div className="downloads">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.garlicbed.glowapp"
                    target="_blank"
                  >
                    <img
                      src="/img/playstore.svg"
                      alt="download glow app on playstore"
                      className="store"
                    />
                  </a>

                  <a
                    href="https://apps.apple.com/app/glowapp-where-you-glow-up/id1639030942"
                    target="_blank"
                  >
                    <img
                      src="/img/appstore.svg"
                      alt="download glow app on appstore"
                      className="store"
                    />
                  </a>
                </div>
              </div>

              <div className="contacts-actions">
                <a
                  className="action-item"
                  href={`tel:${pageContext.contact.phone}`}
                >
                  <i className="fa-solid fa-phone"></i>
                </a>
                <a
                  className="action-item"
                  href={`https://www.google.com/maps/dir/?api=1&destination=${pageContext.contact.location.latitude},${pageContext.contact.location.longitude}`}
                >
                  <i className="fa-solid fa-location-dot"></i>
                </a>
                {/* <a className="action-item">
                  <i className="fa-solid fa-qrcode"></i>
                </a> */}
                <button onClick={toggleShare} className="action-item">
                  <i className="fa-solid fa-share"></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="salon-data-list mt-3 pt-3 pb-5">
          <div>
            <h4>Services</h4>
            <div className="select-services">
              {allServices.map(renderServiceList)}
            </div>
            <button type="button" className="booknow-btn mt-4">
              <a href={url}>Book now</a>
            </button>
          </div>
        </div>

        <Share
          isOpen={showShare}
          toggle={toggleShare}
          title={pageContext.salon}
          description={description}
          shareableLink={pageContext.appointmentLink}
        />
        <AboutUs />
        <Footer />
      </div>
    </>
  )
}

export default Vendors
