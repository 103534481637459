import React from "react"
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  TwitterIcon,
  ViberShareButton,
  ViberIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"
import Modal from "./Modal.component"

type OwnProps = {
  isOpen: boolean
  toggle: () => void
  shareableLink: string
  title: string
  description: string
}

const Share = ({
  isOpen,
  toggle,
  shareableLink,
  title,
  description,
}: OwnProps) => {
  const footer = (
    <div className="share-media">
      <FacebookShareButton url={shareableLink} quote="" hashtag="#GLOWAPP">
        <FacebookIcon round size={35} />
      </FacebookShareButton>
      <LinkedinShareButton
        url={shareableLink}
        title={title}
        summary={description}
        source="GLOWAPP"
      >
        <LinkedinIcon round size={35} />
      </LinkedinShareButton>
      <TelegramShareButton url={shareableLink} title={title}>
        <TelegramIcon size={35} round />
      </TelegramShareButton>
      <TwitterShareButton
        url={shareableLink}
        title={title}
        via="GLOWAPP"
        hashtags={["#GLOWAPP"]}
      >
        <TwitterIcon round size={35} />
      </TwitterShareButton>
      <ViberShareButton url={shareableLink} title={title}>
        <ViberIcon round size={35} />
      </ViberShareButton>
      <WhatsappShareButton url={shareableLink} title={title}>
        <WhatsappIcon round size={35} />
      </WhatsappShareButton>
    </div>
  )
  return (
    <Modal isOpen={isOpen} title="Share link" footer={footer} toggle={toggle}>
      <p>{title}</p>
      <div className="input-group mb-3 mt-3">
        <input type="text" className="form-control" value={shareableLink} />
        <div className="input-group-append">
          <button
            className="btn btn-outline-secondary"
            color="secondary"
            type="button"
          >
            Copy Link
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default Share
